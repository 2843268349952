import createAPI from './APIRequest';

export {transferResources};

function renderErrors($target, text)
{
  Array.prototype.slice.call($target.parentNode.querySelectorAll('.transfer-error'), 0).forEach($error => $error.remove());
  const $fieldErrors = document.createElement('div');
  $fieldErrors.classList.add('help', 'is-danger', 'mb5', 'transfer-error');
  let $fieldError = document.createElement('p');
  $fieldError.textContent = text;
  $fieldErrors.appendChild($fieldError);
  $target.parentNode.insertBefore($fieldErrors, $target);
}

const API = createAPI();
function _transferResources(event) {
  let body = this.dataset.transferBody;
  if (this.dataset.transferForm) {
    body = new FormData(document.getElementById(this.dataset.transferForm));
  }
  API.send(
    this.dataset.transferUrl,
    this.dataset.transferMethod,
    this.dataset.transferAuth,
    body,
    () => {
      Array.prototype.slice.call(this.parentNode.querySelectorAll('.button'), 0).forEach($button => $button.classList.add('is-loading'));
    },
    (response) => {
      if (this.dataset.isBabyCalendarApp && this.dataset.transferForm === 'formResolution' && body.get('review_rating') >= 4) {
        location.href = this.dataset.transferRedirect + '?request_review=1';
      } else {
        this.dataset.transferRedirect && (location.href = this.dataset.transferRedirect);
      }
    },
    (status, response) => {
      if (status === 401) {
        let $link = document.createElement('a');
        $link.href = this.dataset.transferLogin;
        document.body.appendChild($link);
        $link.click();
        $link.remove();
      } else {
        Array.prototype.slice.call(this.parentNode.querySelectorAll('.button'), 0).forEach($button => $button.classList.remove('is-loading'));
        renderErrors(this, this.dataset.transferError);
      }
    }
  );
}

async function transferResources(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener(el.dataset.transferFire, _transferResources);
    });
  }
}
