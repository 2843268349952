import createAPI from './APIRequest';

export {postComment};

function renderErrors(prefix, $form, allErrors) {
  // エラー初期化
  Array.prototype.slice.call($form.querySelectorAll('.' + prefix + 'elements'), 0).forEach($element => $element.classList.remove('is-danger'));
  Array.prototype.slice.call($form.querySelectorAll('.' + prefix + 'errors'), 0).forEach($error => $error.remove());
  // エラーレンダリング
  for (let model in allErrors) {
    let errors = allErrors[model];
    for (let field in errors) {
      let $target = document.getElementById(prefix + model + '_' + field);
      if (!$target) {
        continue;
      }
      $target.classList.add('is-danger');
      let fieldErrors = errors[field];
      let $fieldErrors = document.createElement('div');
      $fieldErrors.classList.add(prefix + 'errors', 'help', 'is-danger', 'mb5');
      if (Array.isArray(fieldErrors)) {
        for (let i = 0; i < fieldErrors.length; i++) {
          for (let subField in fieldErrors[i]) {
            let subFieldErrors = fieldErrors[i][subField];
            for (let key in subFieldErrors) {
              let $fieldError = document.createElement('p');
              $fieldError.textContent = subFieldErrors[key];
              $fieldErrors.appendChild($fieldError);
            }
          }
        }
      } else {
        for (let key in fieldErrors) {
          let $fieldError = document.createElement('p');
          $fieldError.textContent = fieldErrors[key];
          $fieldErrors.appendChild($fieldError);
        }
      }
      $target.parentNode.insertBefore($fieldErrors, $target.nextSibling);
    }
  }
  // モーダル閉じる
  const $change = $form.querySelector('.' + prefix + 'change');
  $change && $change.click();
}

const API = createAPI();
function _postComment(event) {
  const prefix = this.dataset.postPrefix;
  const $form = document.getElementById(prefix + 'form');
  let formData = new FormData($form);
  {
    const cookieData = {};
    {
      // 送信内容確認
      const deleteKeys = [];
      for (const pair of formData.entries()) {
        if (pair[1] instanceof File) {
          // ファイルが選択されていないときはFormDataに含めない
          pair[1].name.length == 0 && pair[1].size <= 0 && deleteKeys.push(pair[0]);
        } else {
          cookieData[pair[0]] = pair[1];
        }
      }
      for (let i = 0; i < deleteKeys.length; i++) {
        formData.delete(deleteKeys[i]);
      }
    }
    {
      // cookie に保存
      const date = new Date();
      date.setTime(date.getTime() + (10 * 60 * 1000));
      document.cookie = $form.name + '=' + encodeURIComponent(JSON.stringify(cookieData)) + ';max-age=600;expires=' + date.toUTCString();
    }
    {
      // PUT 対応
      if (this.dataset.postMethod === 'PUT') {
        formData = cookieData;
      }
    }
  }
  API.send(
    this.dataset.postUrl,
    this.dataset.postMethod || 'POST',
    this.dataset.postAuth,
    formData,
    () => {
      Array.prototype.slice.call($form.querySelectorAll('.' + prefix + 'submit'), 0).forEach($button => $button.classList.add('is-loading'));
    },
    (response) => {
      // cookie 消去
      const date = new Date();
      date.setTime(date.getTime() + 1000);
      document.cookie = $form.name + '={}' + ';max-age=1;expires=' + date.toUTCString();
      // リダイレクト
      location.href = '#comment-' + response.data.id;
      location.reload();
    },
    (status, response) => {
      if (status === 401) {
        let $link = document.createElement('a');
        $link.href = this.dataset.postLogin;
        document.body.appendChild($link);
        $link.click();
        $link.remove();
      } else {
        Array.prototype.slice.call($form.querySelectorAll('.' + prefix + 'submit'), 0).forEach($button => $button.classList.remove('is-loading'));
        status === 422 && renderErrors(prefix, $form, response.data);
      }
    }
  );
}

async function postComment(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener('click', _postComment);
    });
  }
}
