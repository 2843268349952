export {toggleActive};

async function toggleActive(elements) {
  if (elements.length > 0) {

    // Add a click event on each of them
    elements.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });

  }
}
