import createAPI from './APIRequest';

export {clipAdviser};

const API = createAPI();
function _clipAdviser(el) {
  const $fav = el;
  const $babysymbol = el.querySelector('.babysymbol');
  const $button = el.querySelector('.button');
  $babysymbol.addEventListener('animationend', (event) => {
    // CSSアニメーションが終了したら必要ないのでクラスを削除する
    $fav.classList.remove('is-on');
    $babysymbol.style.transform = 'scale(1.3, 1.3)';
  });
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $button.classList.add('is-loading');
      },
      (response) => {
        $button.classList.remove('is-loading');
        if ($current.dataset.clipMethod === 'POST') {
          // dataset更新
          $current.dataset.clipMethod = 'DELETE';
          // CSSアニメーション開始
          $fav.classList.add('is-on');
          $babysymbol.classList.add('babysymbol-heart');
          $babysymbol.classList.remove('babysymbol-heartblank');
          Array.prototype.slice.call(document.querySelectorAll($current.dataset.clipOtherSymbols), 0).forEach($otherSymbol => {
            $otherSymbol.classList.add('babysymbol-heart');
            $otherSymbol.classList.remove('babysymbol-heartblank');
          });
        } else {
          // dataset更新
          $current.dataset.clipMethod = 'POST';
          // html 更新
          $fav.classList.remove('is-on');
          $babysymbol.style.transform = 'none';
          $babysymbol.classList.add('babysymbol-heartblank');
          $babysymbol.classList.remove('babysymbol-heart');
          Array.prototype.slice.call(document.querySelectorAll($current.dataset.clipOtherSymbols), 0).forEach($otherSymbol => {
            $otherSymbol.classList.add('babysymbol-heartblank');
            $otherSymbol.classList.remove('babysymbol-heart');
          });
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        } else {
          $button.classList.remove('is-loading');
        }
      }
    );
  });
}

async function clipAdviser(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipAdviser(el));
  }
}
