export {createDailycalendarBlock};

function createDailycalendarBlock(data, options) {
  const $div = document.createElement('div');
  $div.classList.add('l-status', 'box-border', 'is-white');
  {
    $div.appendChild(data.cw === '' ? createBabyStatusBlock(data) : createPregnancyStatusBlock(data));
  }
  {
    const $row = document.createElement('div');
    $row.classList.add('row');
    {
      const $col = document.createElement('div');
      $col.classList.add('col-xs-12', 'mb10');
      {
        const $col_row = document.createElement('div');
        $col_row.classList.add('row', 'middle-xs');
        {
          const $photo = document.createElement('div');
          $photo.classList.add('col-xs-2');
          const $img = document.createElement('img');
          $img.classList.add('is-rounded', 'is-senmonka');
          $img.src = (data.p_img.replace('.png', '') === data.p_img) ? data.m_img : data.p_img;
          $photo.appendChild($img);
          $col_row.appendChild($photo);
        }
        {
          const $title = document.createElement('div');
          $title.classList.add('col-xs');
          const $text = document.createElement('div');
          $text.classList.add('fw-bold');
          $text.textContent = data.t;
          $title.appendChild($text);
          $col_row.appendChild($title);
        }
        $col.appendChild($col_row);
      }
      $row.appendChild($col);
    }
    {
      const $col = document.createElement('div');
      $col.classList.add('col-xs-12');
      $col.textContent = data.d;
      $row.appendChild($col);
    }
    $div.appendChild($row);
  }
  const $wrap = document.createElement('div');
  const $date = document.createElement('div');
  $date.classList.add('ft-mon', 'fw-bold', 'text-center');
  $date.textContent = options.date;
  $wrap.appendChild($date);
  $wrap.appendChild($div);
  return $wrap;
}

function createPregnancyStatusBlock(data) {
  const $row = document.createElement('div');
  $row.classList.add('row', 'ft-mon', 'mb10');
  {
    const $status = document.createElement('div');
    $status.classList.add('l-status-col-l', 'col-xs-7', 'text-center');
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.textContent = '妊娠 ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cw;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '週と ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cd;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $status.appendChild($p);
    }
    $row.appendChild($status);
    const $days = document.createElement('div');
    $days.classList.add('col-xs-5', 'text-center');
    {
      const $div = document.createElement('div');
      $div.classList.add('label-round', 'is-pink');
      $div.textContent = data.cl.split("\n")[0] + data.cl.split("\n")[1];
      $days.appendChild($div);
    }
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cl.split("\n")[2].replace('日', '');
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $days.appendChild($p);
    }
    $row.appendChild($days);
  }
  return $row;
}


function createBabyStatusBlock(data) {
  const $row = document.createElement('div');
  $row.classList.add('row', 'ft-mon', 'mb10');
  {
    const $status = document.createElement('div');
    $status.classList.add('l-status-col-l', 'col-xs-7', 'text-center');
    {
      const $p = document.createElement('p');
      if (data.cy > 0) {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cy;
        $p.appendChild($span);
      }
      if (data.cy > 0) {
        const $span = document.createElement('span');
        $span.textContent = '才 ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cm;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = 'カ月と ';
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cd;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $status.appendChild($p);
    }
    $row.appendChild($status);
    const $days = document.createElement('div');
    $days.classList.add('col-xs-5', 'text-center');
    {
      const $div = document.createElement('div');
      $div.classList.add('label-round', 'is-pink');
      $div.textContent = 'お誕生日から';
      $days.appendChild($div);
    }
    {
      const $p = document.createElement('p');
      {
        const $span = document.createElement('span');
        $span.classList.add('title', 'is-2', 'c-pink', 'fw-bold');
        $span.textContent = data.cl;
        $p.appendChild($span);
      }
      {
        const $span = document.createElement('span');
        $span.textContent = '日';
        $p.appendChild($span);
      }
      $days.appendChild($p);
    }
    $row.appendChild($days);
  }
  return $row;
}
