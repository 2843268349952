import createAPI from './APIRequest';

export {modalHelpfulReport};

// 役にたった情報の送信
async function modalHelpfulReport(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      // はいボタンを押した場合(はいでAPI送信する)
      const is_helpful_submit = el.querySelector('button.is-helpful-submit'); // はい
      is_helpful_submit && is_helpful_submit.addEventListener('click', (e) => {
        e.target.disabled = true;
        _sendApi(el, 1);
      });
      // いいえボタンを押した場合(いいえでAPI送信する)
      const not_helpful_submit = el.querySelector('button.not-helpful-submit'); // いいえ
      not_helpful_submit && not_helpful_submit.addEventListener('click', (e) => {
        e.target.disabled = true;
        _sendApi(el, 0);
      });
    });
  }
}

// API送信する
function _sendApi(el, reaction) {
  createAPI().send(
    el.dataset.resourcesUrl, // APIのURL
    'POST', // method
    el.dataset.resourcesAuth, // auth
    (new URLSearchParams({
      url: el.dataset.reactionUrl,
      cookie: el.dataset.accessToken,
      reaction: reaction
    })) + '', // body
    () => {
      // before_send
    },
    (response) => {
      // callback
    }
  );
}
