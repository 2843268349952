import createAPI from './APIRequest';

export {clipQuestion};

const API = createAPI();
function _clipQuestion(el) {
  const $babysymbol = el.querySelector('.babysymbol');
  $babysymbol.addEventListener('animationend', (event) => {
    // CSSアニメーションが終了したら必要ないのでクラスを削除する
    el.classList.remove('is-on');
    $babysymbol.classList.add('c-pink');
    $babysymbol.classList.remove('c-gray');
    $babysymbol.style.color = null;
  });
  el.addEventListener('click', (event) => {
    const $current = event.currentTarget;
    API.send(
      $current.dataset.clipUrl,
      $current.dataset.clipMethod,
      $current.dataset.clipAuth,
      $current.dataset.clipBody,
      () => {
        $current.classList.add('is-loading');
        $babysymbol.classList.remove('babysymbol-clip');
        if (!document.body.querySelector('.clip_notification')) {
          // ポップアップ画像を用意
          const $div = document.createElement('div');
          $div.classList.add('clip_notification');
          const $img = document.createElement('img');
          $img.classList.add('clip_notification_img');
          $img.addEventListener('load', event => {
            const $img = event.currentTarget;
            $img.parentNode.classList.add($img.dataset.class);
          });
          $div.appendChild($img);
          $div.addEventListener('animationend', (event) => {
            // CSSアニメーションが終了したら必要ないのでクラスを削除する
            if (event.animationName === 'fadeinAnime') {
              event.currentTarget.classList.remove('is-on');
            } else if (event.animationName === 'fadeoffAnime') {
              event.currentTarget.classList.remove('is-off');
            }
          });
          document.body.appendChild($div);
        }
      },
      (response) => {
        $current.classList.remove('is-loading');
        $babysymbol.classList.add('babysymbol-clip');
        const $img = document.body.querySelector('.clip_notification').querySelector('.clip_notification_img');
        if ($current.dataset.clipMethod === 'POST') {
          // ポップアップCSSアニメーション開始
          $img.dataset.class = 'is-on';
          $img.src = $current.dataset.clipImgClipped;
          // dataset更新
          $current.dataset.clipMethod = 'DELETE';
          // カウンター更新
          $current.querySelector('.clips-counter').textContent = response.total_count;
          // アイコンCSSアニメーション開始
          $current.classList.add('is-on');
        } else {
          // ポップアップCSSアニメーション開始
          $img.dataset.class = 'is-off';
          $img.src = $current.dataset.clipImgUnclipped;
          // dataset更新
          $current.dataset.clipMethod = 'POST';
          // カウンター更新
          $current.querySelector('.clips-counter').textContent = response.total_count;
          // html 更新
          $current.classList.remove('is-on');
          $babysymbol.classList.add('c-gray');
          $babysymbol.classList.remove('c-pink');
          $babysymbol.style.color = null;
        }
      },
      (status, response) => {
        if (status === 401) {
          let $link = document.createElement('a');
          $link.href = $current.dataset.clipLogin;
          document.body.appendChild($link);
          $link.click();
          $link.remove();
        } else {
          $button.classList.remove('is-loading');
          $babysymbol.classList.add('babysymbol-clip');
        }
      }
    );
  });
}

async function clipQuestion(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _clipQuestion(el));
  }
}
