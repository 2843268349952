import createAPI from './APIRequest';

export {like};

function removeAnimationClass(event) {
  // CSSアニメーションが終了したら必要ないのでクラスを削除する
  if (event.animationName === 'opacity01Anime') {
    this.dataset.animation1End = 'done';
  } else if (event.animationName === 'heartAnime') {
    this.dataset.animation2End = 'done';
  } else if (event.animationName === 'jumpAnime') {
    this.dataset.animation3End = 'done';
  }
  if (this.dataset.animation1End && this.dataset.animation2End && this.dataset.animation3End) {
    this.style.cursor = 'default';
    this.querySelector('.is-text').style.opacity = null;
    this.querySelector('.is-text').classList.add('bgc-pink', 'c-white');
    this.querySelector('.is-text').querySelector('.babysymbol').style.transform = 'scale(1.3, 1.3)';
    this.classList.remove('is-on');
  }
}

const API = createAPI();
function _like(event) {
  if (this.querySelector('.is-text').querySelector('.babysymbol').classList.contains('babysymbol-heart')) {
    // いいね済み
    return;
  }
  API.send(
    this.dataset.likeUrl,
    'POST',
    this.dataset.likeAuth,
    this.dataset.likeBody,
    () => {
      this.dataset.animation1End = '';
      this.dataset.animation2End = '';
      this.dataset.animation3End = '';
      this.querySelector('.is-text').style.opacity = '0';
    },
    (response) => {
      // CSSアニメーション開始
      this.classList.add('is-on');
      this.querySelector('.is-text').querySelector('.babysymbol').classList.add('babysymbol-heart');
      this.querySelector('.is-text').querySelector('.babysymbol').classList.remove('babysymbol-heartblank');
      // いいね数更新
      this.querySelector('.is-num').querySelector('span').textContent = response.total_count;
    },
    (status, response) => {
      this.querySelector('.is-text').style.opacity = null;
    }
  );
}

async function like(elements) {
  if (elements.length > 0) {
    elements.forEach(el => {
      el.addEventListener('animationend', removeAnimationClass);
      el.addEventListener('click', _like);
    });
  }
}
