export {createTopicCheckBox};

function createTopicCheckBox(topic, checked) {
  const $div = document.createElement('div');
  $div.classList.add('checkbox', 'col-xs-6');
  {
    const $input = document.createElement('input');
    $input.id = 'checkboxgroup-' + topic.id;
    $input.type = 'checkbox';
    $input.name = 't[]';
    $input.value = topic.id;
    checked && ($input.checked = true);
    $div.appendChild($input);
  }
  {
    const $label = document.createElement('label');
    $label.htmlFor = 'checkboxgroup-' + topic.id;
    {
      const $p = document.createElement('p');
      $p.textContent = topic.name;
      $label.appendChild($p);
    }
    $div.appendChild($label);
  }
  return $div;
}
