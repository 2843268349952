import Swiper from "swiper";

export {swipeGalleryWithThumbs};

async function swipeGalleryWithThumbs(galleries) {
  const swipers = [];
  if (galleries.length > 0) {
    galleries.forEach(el => {
      if (el.dataset.swiperHistory) {
        let url = location.pathname;
        if (url === '/' + el.dataset.swiperHistory) {
          url = location.pathname + '/';
        }
        if (url === '/' + el.dataset.swiperHistory + '/' || url === el.dataset.swiperHistory) {
          const value = el.querySelector('.swiper-slide').dataset.history;
          history.replaceState({value: value}, null,  url + value);
        }
      }
      const $thumbs = document.getElementById(el.dataset.swiperThumbsId);
      const thumbsOptions = JSON.parse($thumbs.dataset.swiperOptions);
      const thumbsSwiper = new Swiper($thumbs, thumbsOptions);
      const galleryOptions = {
        spaceBetween: thumbsOptions.spaceBetween ? thumbsOptions.spaceBetween : 0,
        autoHeight: true,
        history: el.dataset.swiperHistory ? {
          replaceState: true,
          key: el.dataset.swiperHistory
        } : false,
        speed: parseInt(el.dataset.swiperSpeed),
        thumbs: {
          swiper: thumbsSwiper
        },
        on: {
          slideChangeTransitionEnd: function () {
            thumbsOptions.loop && this.thumbs.swiper.slideToLoop(this.realIndex, 0);
          }
        }
      };
      const gallerySwiper = new Swiper(el, galleryOptions);
      const slides = Array.prototype.slice.call(el.querySelectorAll('.swiper-slide'), 0);
      if (slides.length > 0) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            gallerySwiper.updateAutoHeight();
          });
        });
        for (let i = 0; i < slides.length; i++) {
          observer.observe(slides[i], {childList: true, subtree: true});
        }
      }
      swipers.push(gallerySwiper);
    });
  }
  return swipers;
}
