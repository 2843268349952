import createAPI from './APIRequest';
import {createLikeBlock} from "./elementCreators/createLikeBlock";
import {createTopicCheckBox} from "./elementCreators/createTopicCheckBox";
import {createDailycalendarBlock} from "./elementCreators/createDailycalendarBlock";

export {getResources};

function render($viewer, response, options) {
  if (options.element === 'like') {
    $viewer.appendChild(createLikeBlock(response.total_count ? true : false, options));
  } else if (options.element === 'topics') {
    response.data.forEach(topic => {
      $viewer.appendChild(createTopicCheckBox(topic, options.checked.includes(String(topic.id))));
    });
  } else if (options.element === 'dailycalendar') {
    response && response.data && $viewer.appendChild(createDailycalendarBlock(response.data, options));
  }
}

function _getResources(el) {
  createAPI().send(
    el.dataset.resourcesUrl,
    'GET',
    el.dataset.resourcesAuth,
    null,
    function() {},
    function (response) {
      render(el, response, JSON.parse(el.dataset.renderOptions));
    }
  );
}

async function getResources(elements) {
  if (elements.length > 0) {
    elements.forEach(el => _getResources(el));
  }
}
