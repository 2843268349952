import '../sass/Front/style.sass';
import {clipAdviser} from "./modules/clipAdviser";
import {searchResources} from "./modules/searchResources";
import {toggleActive} from "./modules/toggleActive";
import createPhotosUploader from "./modules/MultiPhotosUploader";
import {postQuestion} from "./modules/postQuestion";
import {postComment} from "./modules/postComment";
import {modalHelpfulReport} from "./modules/modalHelpfulReport";
import {scrollToTop} from "./modules/scrollToTop";
import {previewFormFields} from "./modules/previewFormFields";
import {transferResources} from "./modules/transferResources";
import {clipQuestion} from "./modules/clipQuestion";
import Swiper from "swiper";
import {swipeGalleryWithThumbs} from "./modules/swipeGalleryWithThumbs";
import {getResources} from "./modules/getResources";
import "./modules/scrollViewPopup";

document.addEventListener('DOMContentLoaded', () => {

  let last_known_scroll_position = 0;
  let ticking = false;
  window.addEventListener('scroll', function(e) {
    last_known_scroll_position = window.scrollY;
    if (!ticking) {
      window.requestAnimationFrame(function() {
        const $top = document.getElementById('totop');
        let $search = document.getElementById('scroll-search');
        let $sort = document.getElementById('scroll-sort');
        let $style = '120px';
        if (last_known_scroll_position > 100) {
          const $overlayAd = document.getElementById('ats-custom-overlay-wrapper');
          if ($overlayAd) {
            $top.style.bottom = Math.max(70, ($overlayAd.offsetHeight + 20)) + 'px';
            $style = Math.max(120, ($overlayAd.offsetHeight + 20)) + 'px';
          } else {
            $top.style.bottom = '70px';
          }
          $top.classList.add('ease-in');
        } else if ($top.classList.contains('ease-in') && !$top.classList.contains('ease-out')) {
          $top.classList.add('ease-out');
        }
        if (last_known_scroll_position > 200) {
          if ($search !== null) {
            $search.classList.add('is-in');
            $search.style.bottom = $style;
          }else if ($sort !== null) {
            $sort.classList.add('is-in');
            $sort.style.bottom = $style;
          }
        }else {
          if ($search !== null) {
            $search.classList.remove('is-in');
          }else if ($sort !== null) {
            $sort.classList.remove('is-in');
          }
        }
        ticking = false;
      });
    }
    ticking = true;
  });

  document.querySelectorAll('.modal').forEach($modal => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (document.querySelector('.modal.is-active')) {
          document.body.classList.add('html-clipped');
        } else {
          document.body.classList.remove('html-clipped');
        }
      });
    });
    observer.observe($modal, { attributes: true });
  });

  let selectors;

  selectors = '.js-scroll-to-top';
  scrollToTop(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-upload-photos';
  createPhotosUploader(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-clip-adviser';
  clipAdviser(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-clip-question';
  clipQuestion(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-preview-form-fields';
  previewFormFields(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-question';
  postQuestion(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-post-comment';
  postComment(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-modal-helpful-report';
  modalHelpfulReport(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-transfer-resources';
  transferResources(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-toggle-active';
  toggleActive(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  selectors = '.js-get-resources';
  getResources(Array.prototype.slice.call(document.querySelectorAll(selectors), 0));

  let hash;

  hash = 'edit-question';
  location.hash === ('#' + hash) && document.getElementById('js-anchor-' + hash) && document.getElementById('js-anchor-' + hash).click();

  hash = 'edit-comment';
  location.hash === ('#' + hash) && document.getElementById('js-anchor-' + hash) && document.getElementById('js-anchor-' + hash).click();
});

window.addEventListener('popstate', () => {
  sessionStorage.setItem('popstate', '1'); // ブラウザバック実行済み判定をセット（safari用）
});

window.addEventListener('load', () => {

  document.body.dataset.state = JSON.stringify(history.state);

  let selectors;

  selectors = '.js-swiper';
  Array.prototype.slice.call(document.querySelectorAll(selectors), 0).forEach(el => new Swiper(el, JSON.parse(el.dataset.swiperOptions)));

  selectors = '.js-swiper-thumbs-gallery';
  swipeGalleryWithThumbs(Array.prototype.slice.call(document.querySelectorAll(selectors), 0)).then((swipers) => {
    selectors = '.js-search-resources';
    searchResources(Array.prototype.slice.call(document.querySelectorAll(selectors), 0)).then((lazy) => {
      sessionStorage.setItem('load', location.pathname);
      swipers.forEach(swiper => {
        swiper.on('slideChangeTransitionEnd', () => {
          sessionStorage.setItem('load', location.pathname);
          if (lazy !== {}) {
            const slides = swiper.el.querySelectorAll('.swiper-slide[data-history]');
            const tabName = slides[swiper.activeIndex].dataset.history;
            if (tabName in lazy && lazy[tabName].length > 0) {
              const allFunc = lazy[tabName];
              lazy[tabName] = [];
              allFunc.forEach(func => func());
            }
          }
        });
      });
    });
  });

});
