document.addEventListener('DOMContentLoaded', function () {

  const FlagPopup = document.getElementById('js-flag-popup');

  if (FlagPopup) {

    const options = {
      root: null,
      rootMargin: "0px 0px -300px 0px",
      threshold: 0.0
    };

    let FlagPopupOnce = false;

    const observer = new IntersectionObserver((entries) => {
      for (const e of entries) {

        if (e.isIntersecting && FlagPopupOnce == !true) {
          document.getElementById('modal_scrollView').classList.add('is-active');
          FlagPopupOnce = true;
        }

      }
    }, options);

    observer.observe(FlagPopup);
  }



});
